<template>
  <div class="flex flex-col">
    <PublicHeader />
    <!--<NuxtLoadingIndicator :throttle="0" color="#39C0C4" />-->
    <PublicMobileFooter />
    <div class="pt-[60px] bg-white dark:bg-[#232323]">
      <suspense>
        <slot />
      </suspense>
    </div>
    <Footer />
    <IconScrollTop class="[&>*]:fill-cs-light-blue fixed bottom-5 right-5 cursor-pointer" @click="$scrollToTop" />
  </div>
</template>

<script lang="ts" setup>
import IconScrollTop from 'assets/icons/scroll-top.svg'
import {COLOR_MODE_DARK} from '~/plugins/darkTheme'
import {useToggleColorMode} from '~/composables/common'

const route = useRoute()
const {$getAbsoluteUrl, $scrollToTop} = useNuxtApp()

// Initialize color state
// We need to do it here because until the component it's mounted
// colorMode it's not updated with the good value
onMounted(() => {
  const colorMode = useColorMode()
  useToggleColorMode(colorMode.value === COLOR_MODE_DARK)
})

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: $getAbsoluteUrl() + route.path
    }
  ]
}))
</script>
